<template>
  <div :style="{ height: iHeight - 40 + 'px' }" class="content-placeholder-no-data">
    <div class="content-imgs">
      <img class="content-background-1" src="@/assets/imgs_no_data_text_global/backgroundImg.svg" />
      <img class="content-background-2" src="@/assets/imgs_no_data_text_global/backgroundImg2.svg" />
      <img class="content-img-left-loud" src="@/assets/imgs_no_data_text_global/nubeIzquierda.svg" />
      <img class="content-img-right-cloud" src="@/assets/imgs_no_data_text_global/nubeDerecha.svg" />
      <img class="content-img-question" src="@/assets/imgs_no_data_text_global/ImgQuestionPrincipal.svg" />
      <div class="content-text-empty" v-html="sTextEmpty" @click="setAdd" />
      <!-- <p>No existen cuentas registradas.</p>
        <p>
          Comienza registrando una.
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iHeight: Number,
    sTextEmpty: String,
    bAdminPermissions: Boolean,
    toVieworModal: { type: Boolean, default: false },
    setDialog: String
  },
  data() {
    return {
      screenWidth: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = window.innerWidth - 900;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
    setAdd() {
      if (this.bAdminPermissions) {
        if (this.toVieworModal) {
          this.$router
            .push({
              name: this.setDialog,
              params: {},
            })
            .catch(() => { });
        } else {
          this.$emit("setDialogAdd")
        }
      }
    }
  },
};
</script>

<style scoped>
.content-placeholder-no-data {
  width: auto !important;
  max-width: auto !important;
  min-width: auto !important;
  text-align: -webkit-center;
  align-items: center;
  display: flex;
  place-content: center;
  padding: 40px 0px 0px 0px;
  overflow: hidden !important;
}

.content-imgs {
  height: 360px;
  width: 700px;
  position: relative;
}

.content-background-1 {
  position: absolute;
  height: 350px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  transition: 0.2s;
}

.content-background-2 {
  position: absolute;
  height: 385px;
  left: 70px;
  right: 0px;
  bottom: 0px;
  top: -30px;
  transition: 0.2s;
}

.content-img-left-loud {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 120px;
  top: 100px;
  transition: 0.2s;
}

.content-img-right-cloud {
  position: absolute;
  height: 90px;
  width: 90px;
  right: 150px;
  top: 160px;
  transition: 0.2s;
}

.content-img-question {
  position: absolute;
  height: 230px;
  bottom: 0px;
  top: 50px;
  left: 240px;
  right: 0px;
  transition: 0.2s;
}

.content-imgs:hover .content-img-left-loud {
  left: 80px;
  transition: 0.2s;
}

.content-imgs:hover .content-img-right-cloud {
  right: 100px;
  transition: 0.2s;
}

.content-imgs:hover .content-img-question {
  top: 5px;
  transition: 0.2s;
}

.content-imgs:hover .content-background-1 {
  /* transform: scale(0.9); */
  transition: 0.2s;
}

.content-imgs:hover .content-background-2 {
  /* transform: scale(0.9); */
  transition: 0.2s;
}

.content-text-empty {
  height: 200px;
  width: 400px;
  position: absolute;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  text-align: center;
  letter-spacing: 0px;
  bottom: 120px;
  bottom: -120px;
  left: 150px;
  right: 0px;
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-imgs {
    height: 360px;
    width: 100%;
    /* background-color: black; */
    position: relative;
  }

  .content-background-1 {
    position: absolute;
    height: 190px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
    top: 40px;
    transition: 0.2s;
  }

  .content-background-2 {
    position: absolute;
    height: 200px;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
    top: 35px;
    transition: 0.2s;
  }

  .content-img-left-loud {
    position: absolute;
    height: 35px;
    width: 35px;
    margin-left: auto;
    margin-right: auto;
    left: -220px;
    right: 0;
    top: 100px;
    transition: 0.2s;
  }

  .content-img-right-cloud {
    position: absolute;
    height: 45px;
    width: 45px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: -190px;
    top: 130px;
    transition: 0.2s;
  }

  .content-img-question {
    position: absolute;
    height: 130px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 60px;
    transition: 0.2s;
  }

  .content-text-empty {
    height: auto;
    width: 100%;
    position: absolute;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
    text-align: center;
    letter-spacing: 0px;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .content-imgs:hover .content-img-left-loud {
    left: -250px;
    transition: 0.2s;
  }

  .content-imgs:hover .content-img-right-cloud {
    right: -240px;

    transition: 0.2s;
  }

  .content-imgs:hover .content-img-question {
    top: 40px;
    transition: 0.2s;
  }

  .content-imgs:hover .content-background-1 {
    /* transform: scale(0.9); */
    transition: 0.2s;
  }

  .content-imgs:hover .content-background-2 {
    /* transform: scale(0.9); */
    transition: 0.2s;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-imgs {
    height: 360px;
    width: 100%;
    /* background-color: black; */
    position: relative;
  }

  .content-background-1 {
    position: absolute;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
    top: 30px;
    transition: 0.2s;
  }

  .content-background-2 {
    position: absolute;
    height: 290px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
    top: 20px;
    transition: 0.2s;
  }

  .content-img-left-loud {
    position: absolute;
    height: 60px;
    width: 60px;
    margin-left: auto;
    margin-right: auto;
    left: -250px;
    right: 0;
    top: 100px;
    transition: 0.2s;
  }

  .content-img-right-cloud {
    position: absolute;
    height: 80px;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: -260px;
    top: 130px;
    transition: 0.2s;
  }

  .content-img-question {
    position: absolute;
    height: 170px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 60px;
    transition: 0.2s;
  }

  .content-text-empty {
    height: 200px;
    width: 400px;
    position: absolute;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
    text-align: center;
    letter-spacing: 0px;
    bottom: -90px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .content-imgs:hover .content-img-left-loud {
    left: -280px;
    transition: 0.2s;
  }

  .content-imgs:hover .content-img-right-cloud {
    right: -300px;

    transition: 0.2s;
  }

  .content-imgs:hover .content-img-question {
    top: 30px;
    transition: 0.2s;
  }

  .content-imgs:hover .content-background-1 {
    /* transform: scale(0.9); */
    transition: 0.2s;
  }

  .content-imgs:hover .content-background-2 {
    /* transform: scale(0.9); */
    transition: 0.2s;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-imgs {
    height: 360px;
    width: 100%;
    /* background-color: black; */
    position: relative;
  }

  .content-background-1 {
    position: absolute;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
    top: 30px;
    transition: 0.2s;
  }

  .content-background-2 {
    position: absolute;
    height: 290px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0px;
    top: 20px;
    transition: 0.2s;
  }

  .content-img-left-loud {
    position: absolute;
    height: 60px;
    width: 60px;
    margin-left: auto;
    margin-right: auto;
    left: -250px;
    right: 0;
    top: 100px;
    transition: 0.2s;
  }

  .content-img-right-cloud {
    position: absolute;
    height: 80px;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: -260px;
    top: 130px;
    transition: 0.2s;
  }

  .content-img-question {
    position: absolute;
    height: 170px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 60px;
    transition: 0.2s;
  }

  .content-text-empty {
    height: 200px;
    width: 400px;
    position: absolute;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
    text-align: center;
    letter-spacing: 0px;
    bottom: -90px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  .content-imgs:hover .content-img-left-loud {
    left: -280px;
    transition: 0.2s;
  }

  .content-imgs:hover .content-img-right-cloud {
    right: -300px;

    transition: 0.2s;
  }

  .content-imgs:hover .content-img-question {
    top: 10px;
    transition: 0.2s;
  }

  .content-imgs:hover .content-background-1 {
    /* transform: scale(0.9); */
    transition: 0.2s;
  }

  .content-imgs:hover .content-background-2 {
    /* transform: scale(0.9); */
    transition: 0.2s;
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>